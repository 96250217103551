import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { useIntl } from 'gatsby-plugin-intl'
import { capitalize } from '../helpers/functions'

// TODO add props validation
const Stores = ({ app, appStoreClasses, playStoreClasses }) => {
  const data = useStaticQuery(graphql`
    query {
      appStoreEs: file(relativePath: { eq: "stores/appstore-es.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      appStoreEn: file(relativePath: { eq: "stores/appstore-en.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      appStoreDe: file(relativePath: { eq: "stores/appstore-de.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      playStoreEs: file(relativePath: { eq: "stores/playstore-es.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      playStoreEn: file(relativePath: { eq: "stores/playstore-en.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      playStoreDe: file(relativePath: { eq: "stores/playstore-de.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const { formatMessage, locale } = useIntl()
  const capitalizedLocal = capitalize(locale)

  return (
    <>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={formatMessage({ id: `pages.downloads.apps.${app}.appStore` })}
      >
        <Img
          alt="App Store"
          className={appStoreClasses}
          fluid={data[`appStore${capitalizedLocal}`].childImageSharp.fluid}
        />
      </a>

      <a
        target="_blank"
        rel="noopener noreferrer"
        href={formatMessage({ id: `pages.downloads.apps.${app}.playStore` })}
      >
        <Img
          alt="Play Store"
          className={playStoreClasses}
          fluid={data[`playStore${capitalizedLocal}`].childImageSharp.fluid}
        />
      </a>
    </>
  )
}

export default Stores
