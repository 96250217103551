import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Stores from '../components/stores'
import styles from './tutor.module.css'

const TutorPage = ({ data }) => {
  const { formatMessage } = useIntl()

  const downloadablePath = formatMessage({
    id: 'pages.tutor.sections.guide.file',
  })
  const hasDownloadableFile = downloadablePath !== 'NULL'

  return (
    <Layout>
      <SEO
        lang={formatMessage({ id: 'lang' })}
        title={formatMessage({ id: 'pages.tutor.title' })}
        description={formatMessage({ id: 'pages.tutor.description' })}
      />

      <section className={styles.sectionLanding}>
        <div className="custom-container pt-32 pb-48 text-white">
          <div className="badge">
            <Img alt="Tutor badge" fluid={data.badge.childImageSharp.fluid} />
          </div>

          <h1 className="heading text-yellow-light uppercase mt-4">
            <FormattedMessage id="pages.tutor.title" />
          </h1>

          <p className="mt-8 text-yellow-light text-lg font-semibold">
            <FormattedMessage id="pages.tutor.sections.landing.title" />
          </p>

          <p className="mt-4">
            <FormattedMessage id="pages.tutor.sections.landing.p1" />
          </p>

          <p className="mt-4">
            <FormattedMessage id="pages.tutor.sections.landing.p2" />
          </p>

          <div className="mt-8 flex justify-center items-center">
            <Stores
              app="trainAtHome"
              appStoreClasses="w-32 mr-8"
              playStoreClasses="w-32"
            />
          </div>
        </div>

        <div className="relative custom-container custom-container--extralarge">
          <div className={styles.tutor}>
            <Img alt="Tutor 01" fluid={data.tutor.childImageSharp.fluid} />
          </div>

          <div className={styles.tutorRight}>
            <Img alt="Tutor 02" fluid={data.tutorRight.childImageSharp.fluid} />
          </div>
        </div>
      </section>

      <section className="bg-blue-darkest">
        <div className="custom-container py-32">
          <h2 className="heading text-yellow-light">
            <FormattedMessage id="pages.tutor.sections.guide.title" />
          </h2>

          <div className="mt-8">
            <div className="w-48 mx-auto">
              <Img
                alt="Download 01"
                fluid={data.download01.childImageSharp.fluid}
              />
            </div>

            <div className="w-3/5 mx-auto mt-2">
              <p className="text-white">
                <FormattedMessage id="pages.tutor.sections.guide.description" />
              </p>
            </div>

            {hasDownloadableFile && (
              <a
                className="btn inline-block mt-8"
                href={downloadablePath}
                download
              >
                <FormattedMessage id="pages.tutor.sections.guide.button" />
              </a>
            )}

            {!hasDownloadableFile && (
              <span className="inline-block mt-8 text-yellow-light font-fredoka">
                <FormattedMessage id="general.comingSoon" />
              </span>
            )}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    badge: file(relativePath: { eq: "badges/parent-color.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tutor: file(relativePath: { eq: "tutor/tutor.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tutorRight: file(relativePath: { eq: "tutor/tutor-02.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    download01: file(relativePath: { eq: "tutor/download-01.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default TutorPage
